import React, { useState } from "react";
import { ProfileList } from "./components/Profiles";
import { Animation } from "./components/Animation";
import { MainLayout } from "./components/MainContent";
import "./App.css";

import featuredPosterLoumflix from "./assets/image/featured_poster_loumflix.png";
import loBreakingBad from "./assets/image/lo_breaking_bad.png";
import logoLoumflix from "./assets/image/logo_loumflix.png";
import userProfilePicture from "./assets/image/profile_2.png";
import posterMovieLoumflix from "./assets/image/loumflix_movie/poster_movie_loumflix.png";

const App = () => {
  const [isProfilesVisible, setIsProfilesVisible] = useState(false); // WARNING PUT TRUE WHEN NOT DEVING
  const [isAnimationActive, setIsAnimationActive] = useState(false);

  const imagesToPreload = [
    featuredPosterLoumflix,
    loBreakingBad,
    logoLoumflix,
    userProfilePicture,
    posterMovieLoumflix,
  ];

  const preloadImages = () => {
    imagesToPreload.forEach((imageUrl) => {
      console.log("Preloading image: ", imageUrl);
      const img = new Image();
      img.src = imageUrl;
    });
  };

  const handleProfileClick = () => {
    setIsProfilesVisible(false);
    setIsAnimationActive(true);
    preloadImages(); // Preload images when the animation starts
    setTimeout(() => {
      setIsAnimationActive(false);
    }, 5000);
  };

  return (
    <div className="App">
      {isProfilesVisible && (
        <ProfileList handleProfileClick={handleProfileClick} />
      )}
      {isAnimationActive && <Animation isAnimationActive={true} />}
      {!isProfilesVisible && !isAnimationActive && <MainLayout />}
    </div>
  );
};

export default App;
