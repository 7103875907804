import React from "react";
import { MovieListItem } from ".";

const MovieList = ({ categories, handleMovieClick }) => {
  return (
    <>
      {categories.map((categoryItem) => (
        <div key={categoryItem.category} className="lolomoRow lolomoRow_title_card">
          <h2 className="rowHeader">
            <a href="/#" className="rowTitle">
              <div className="row-header-title">{categoryItem.category}</div>
            </a>
          </h2>
          <div className="rowContainer rowContainer_title_card has-expanded-info-density">
            <div className="ptrack-container">
              <div className="rowContent slider-hover-trigger-layer">
                <div className="slider">
                  <div className="sliderMask showPeek">
                    <div className="sliderContent row-with-x-columns">
                      {categoryItem.movies.map((movie) => (
                        <MovieListItem
                          key={movie.id}
                          movieDetail={movie}
                          handleMovieClick={() => handleMovieClick(movie)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default MovieList;
