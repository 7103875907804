import React from "react";
import featuredPosterLoumflix from "../../assets/image/featured_poster_loumflix.png";
import loBreakingBad from "../../assets/image/lo_breaking_bad.png";

const FeaturePresentation = () => {
  return (
    <div className="volatile-billboard-animations-container">
      <div className="billboard-row">
        <div className="ptrack-container billboard-presentation-tracking">
          <div className="billboard-presentation-tracking">
            <div className="billboard-presentation-tracking">
              <div className="billboard billboard-pane billboard-originals trailer-billboard">
                <div className="billboard-motion dismiss-mask">
                  <div className="motion-background-component bottom-layer full-screen">
                    <div className="hero-image-wrapper">
                      <img
                        className="hero static-image image-layer"
                        src={featuredPosterLoumflix}
                        alt=""
                        decoding="async"
                      />
                      <div className="trailer-vignette vignette-layer"></div>
                      <div className="hero-vignette vignette-layer"></div>
                    </div>
                  </div>
                </div>
                <div className="fill-container">
                  <div className="info meta-layer">
                    <div className="logo-and-text meta-layer">
                      <div className="titleWrapper">
                        <div className="billboard-title">
                          <img
                            alt="Loumflix"
                            className="title-logo"
                            src={loBreakingBad}
                            title="Loumflix"
                            decoding="async"
                          />
                        </div>
                      </div>
                      <div className="info-wrapper">
                        <div className="info-wrapper-fade">
                          <div className="episode-title-container"></div>
                          <div className="synopsis-fade-container">
                            <div className="synopsis no-supplemental">
                              Un développeur web créatif conçoit un portfolio en
                              ligne inspiré de Netflix, mêlant design
                              sophistiqué et interactivité, pour se distinguer
                              dans un marché concurrentiel et forger un avenir
                              professionnel prometteur.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="billboard-links button-layer forward-leaning">
                        {/* <a href="/#" className="playLink isToolkit">
                          <button
                            className="color-primary hasLabel hasIcon button-play"
                            type="button"
                          >
                            <div>
                              <div
                                className="medium"
                                style={{ height: "2.4rem", width: "2.4rem" }}
                              >
                                <svg
                                  style={{ height: "100%", width: "100%" }}
                                  width="34"
                                  height="34"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div style={{ width: "1rem" }}></div>
                            <span className="text-play-btn">Lecture</span>
                          </button>
                        </a> */}
                        <button
                          className="color-secondary hasLabel hasIcon button-more-info"
                          type="button"
                        >
                          <div>
                            <div
                              className="medium"
                              style={{ height: "2.4rem", width: "2.4rem" }}
                            >
                              <svg
                                style={{ height: "100%", width: "100%" }}
                                width="34"
                                height="34"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM13 10V18H11V10H13ZM12 8.5C12.8284 8.5 13.5 7.82843 13.5 7C13.5 6.17157 12.8284 5.5 12 5.5C11.1716 5.5 10.5 6.17157 10.5 7C10.5 7.82843 11.1716 8.5 12 8.5Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div style={{ width: "1rem" }}></div>
                          <span className="text-play-btn">Plus d'infos</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturePresentation;
