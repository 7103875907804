import React from "react";
import { PageHeader } from "../Header";
import { ContentArea } from ".";

const MainLayout = () => {
  return (
    <div
      className="container-main bd dark-background"
      style={{ overflow: "visible", top: "0px" }}
    >
      <PageHeader />
      <ContentArea />
    </div>
  );
};

export default MainLayout;
