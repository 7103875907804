import React, { useRef, useEffect, useState } from "react";

import logoLoumflix from "../../assets/image/logo_loumflix.png";
import featuredPosterLoumflix from "../../assets/image/featured_poster_loumflix.png";
import video from "../../assets/video/example_2.mp4";
import { EpisodeItem } from ".";

const MovieModal = ({
  isOpen,
  onClose,
  children,
  style,
  selectedMovieDetails,
}) => {
  const [posterOpacity, setPosterOpacity] = useState(1);

  const videoRef = useRef(null);

  useEffect(() => {
    // Check if the modal is open and the video ref is attached to a DOM element
    if (isOpen && videoRef.current) {
      videoRef.current.play().catch((error) => {
        // Handle any errors that occur during playback
        console.error("Error attempting to play video:", error);
      });
      setPosterOpacity(0);
    }
  }, [isOpen]); // This effect depends on the isOpen state

  if (!isOpen) return null;

  const handleVideoEnd = () => {
    setPosterOpacity(1);
    console.log("Video has ended");
    // Perform any actions after the video ends here
  };

  return (
    <>
      <div
        className="focus-trap-wrapper previewModal--wrapper detail-modal has-smaller-buttons"
        style={{ display: "flex", ...style }}
        tabIndex="-1"
      >
        <div
          role="dialog"
          aria-modal="true"
          tabIndex="-1"
          className="previewModal--container detail-modal has-smaller-buttons"
        >
          <div className="previewModal--player_container detail-modal has-smaller-buttons">
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <video
                  ref={videoRef}
                  src={video}
                  data-videoid="81724735"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onEnded={handleVideoEnd}
                  playsInline
                ></video>
                <div
                  className="player-timedtext"
                  style={{ display: "none", direction: "ltr" }}
                ></div>
              </div>
            </div>

            <div className="storyArt detail-modal has-smaller-buttons">
              <img
                src={featuredPosterLoumflix}
                alt="Loumflix"
                className="playerModel--player__storyArt detail-modal has-smaller-buttons"
                style={{ opacity: posterOpacity }}
              />
            </div>
            <div>
              <div className="previewModal--player-titleTreatmentWrapper">
                <div className="previewModal--player-titleTreatment-left previewModal--player-titleTreatment detail-modal has-smaller-buttons detail-modal has-smaller-buttons">
                  <img
                    className="previewModal--player-titleTreatment-logo"
                    alt="Loumflix"
                    title="Loumflix"
                    src={logoLoumflix}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="previewModal-close" onClick={onClose}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ltr-4z3qvp e1svuwfo1"
              role="button"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5858 12L2.29291 3.70706L3.70712 2.29285L12 10.5857L20.2929 2.29285L21.7071 3.70706L13.4142 12L21.7071 20.2928L20.2929 21.7071L12 13.4142L3.70712 21.7071L2.29291 20.2928L10.5858 12Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div
            className="previewModal--info"
            style={{ opacity: "1", transform: "none" }}
          >
            <div
              className="detail-modal-container"
              style={{ paddingBottom: "25px" }}
            >
              <div className="ptrack-container">
                <div>
                  <div className="previewModal--detailsMetadata detail-modal has-smaller-buttons">
                    <div className="previewModal--detailsMetadata-left">
                      <div className="previewModal--detailsMetadata-info">
                        <div>
                          <div className="">
                            <div className="videoMetadata--container">
                              <div className="videoMetadata--first-line">
                                <span className="match-score-wrapper">
                                  <div className="show-match-score rating-inner">
                                    <span className="match-score">
                                      Recommandé à{" "}
                                      {selectedMovieDetails.recommended}&nbsp;%
                                    </span>
                                  </div>
                                </span>
                              </div>
                              <div className="videoMetadata--second-line">
                                <div className="year">
                                  {selectedMovieDetails.releaseDate}
                                </div>
                                <span className="duration">
                                  {selectedMovieDetails.duration}
                                </span>
                                <span className="player-feature-badge">HD</span>
                              </div>
                            </div>
                          </div>
                          <div className="ltr-7t0zr9">
                            <a href="/#">
                              <span className="maturity-rating">
                                <span className="maturity-number">
                                  {selectedMovieDetails.maturity}
                                </span>
                              </span>
                            </a>
                            <span
                              className="ltr-1q4vxyr"
                              style={{ paddingLeft: "8px" }}
                            >
                              {selectedMovieDetails.genre}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="ltr-s5xdrg"></div>
                      <p className="preview-modal-synopsis previewModal--text">
                        {selectedMovieDetails.description}
                      </p>
                    </div>
                    <div className="previewModal--detailsMetadata-right">
                      <div
                        className="previewModal--tags"
                        data-uia="previewModal--tags-person"
                      >
                        <span className="previewModal--tags-label">
                          Distribution&nbsp;:&nbsp;
                        </span>
                        <span className="tag-item">
                          <a href="/#">{selectedMovieDetails.distribution} </a>
                        </span>
                      </div>
                      <div className="previewModal--tags">
                        <span className="previewModal--tags-label">
                          Genres&nbsp;:&nbsp;
                        </span>
                        {selectedMovieDetails.genres.map((genre) => (
                          <span className="tag-item" key={genre}>
                            <a href="/#">{genre}, </a>
                          </span>
                        ))}
                      </div>
                      <div className="previewModal--tags">
                        <span className="previewModal--tags-label">
                          Ce programme est&nbsp;:&nbsp;
                        </span>
                        <span className="tag-item">
                          <a href="/#">{selectedMovieDetails.programType}</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ptrack-container">
                <div className="episodeSelector" data-uia="episode-selector">
                  <div className="episodeSelector-container">
                    {selectedMovieDetails.episodes &&
                    selectedMovieDetails.episodes.length > 0 ? (
                      selectedMovieDetails.episodes.map((episode, index) => (
                        <EpisodeItem key={index} episode={episode} />
                      ))
                    ) : (
                      // this is a movie
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div tabIndex="-1" style={{ opacity: 0.7, display: "block" }}>
        <div
          className="previewModal--backDrop"
          tabIndex="-1"
          data-uia="previewModal--backDrop"
          onClick={onClose}
        ></div>
      </div>
    </>
  );
};

export default MovieModal;
