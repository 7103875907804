import React from "react";

const MovieListItem = ({ movieDetail, handleMovieClick }) => {
  const getImagePath = (imageName) =>
    require("../../assets/image/" + imageName);

  return (
    <div className="slider-item slider-item-2" onClick={handleMovieClick}>
      <div className="title-card-container">
        <div className="title-card">
          <a href="/#" className="slider-refocus">
            <div className="boxart-size-16x9 boxart-container boxart-rounded">
              <img
                className="boxart-image boxart-image-in-padded-container"
                src={getImagePath(movieDetail.movieCardImage)}
              />
              <div className="fallback-text-container">
                <p className="fallback-text">{movieDetail.title}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="card-overlay">
        <div className="recommandation-text">
          Recommandé à {movieDetail.recommended} %
        </div>
        <button
          className="color-supplementary hasIcon round button-card-details button-bottom-right"
          type="button"
        >
          <div>
            <div className="small">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 15.5859L19.2928 8.29297L20.7071 9.70718L12.7071 17.7072C12.5195 17.8947 12.2652 18.0001 12 18.0001C11.7347 18.0001 11.4804 17.8947 11.2928 17.7072L3.29285 9.70718L4.70706 8.29297L12 15.5859Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default MovieListItem;
