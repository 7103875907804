import React, { useState } from "react";
import { FeaturePresentation } from ".";
import { MovieList, MovieModal } from "../Movie";
import categoriesData from "../../data/movies.json"; // Adjust the path according to your file structure

const ContentArea = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMovieDetails, setSelectedMovieDetails] = useState(null);
  const [modalStyle, setModalStyle] = useState({});

  const handleMovieClick = (movieDetails) => {
    const mobileBreakpoint = 768;
    const isMobile = window.innerWidth <= mobileBreakpoint;

    let topOffset = "0px";
    if (!isMobile) {
      const currentScrollY = window.scrollY;
      topOffset = `${currentScrollY + 20}px`;
    }

    setModalStyle({
      top: topOffset,
    });

    setSelectedMovieDetails(movieDetails);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="mainView" role="main">
        <div className="lolomo is-fullbleed">
          <FeaturePresentation />
          <MovieList categories={categoriesData} handleMovieClick={handleMovieClick} />
        </div>
      </div>
      <MovieModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        style={modalStyle}
        selectedMovieDetails={selectedMovieDetails}
      ></MovieModal>
    </>
  );
};

export default ContentArea;
