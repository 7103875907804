import React from "react";
import logoLoumflix from "../../assets/image/logo_loumflix.png";

const SiteLogo = () => {
  return (
    <a href="/#" className="logo icon-logoUpdate active" aria-label="Loumflix">
      <img src={logoLoumflix} alt="Logo" style={{ width: "9rem" }} />
    </a>
  );
};

export default SiteLogo;
