import React from "react";
import { ProfileListItem } from ".";
// import './ProfileList.css'; // Assuming CSS Modules or similar styling approach
import profileImage1 from "../../assets/image/profile_1.png";
import profileImage2 from "../../assets/image/profile_2.png";
import profileImage3 from "../../assets/image/profile_3.png";

const profiles = [
  { id: "profile-manager", name: "Le Manager", image: profileImage1 },
  { id: "profile-louis", name: "Louis", image: profileImage2 },
  { id: "profile-binge-watcher", name: "Binge-Watcher", image: profileImage3 },
];

const ProfileList = ({ handleProfileClick }) => {
  return (
    <div className="container-profiles">
      <div className="centered">
        <div className="list-profiles">
          <h1 className="profile-label">Qui est-ce ?</h1>
          <ul className="choose-profile">
            {profiles.map((profile) => (
              <ProfileListItem
                key={profile.id}
                profile={profile}
                onClick={handleProfileClick}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProfileList;
