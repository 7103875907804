import React from "react";

const EpisodeItem = ({ episode }) => {
  const getImagePath = imageName => require("../../assets/image/" + imageName);

  return (
    <div
      className="titleCardList--container episode-item"
      tabIndex="0"
      aria-label={episode.title}
      data-uia="titleCard--container"
      role="button"
    >
      <div className="titleCard-title_index">{episode.id}</div>
      <div className="titleCard-imageWrapper">
        <img
        
          src={getImagePath(episode.episodeCardImage)}
          alt={episode.title}
        />
        <div className="titleCard-playIcon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="titleCard-playSVG ltr-4z3qvp e1svuwfo1"
            data-name="Play"
            aria-hidden="true"
          >
            <path
              d="M5 2.69127C5 1.93067 5.81547 1.44851 6.48192 1.81506L23.4069 11.1238C24.0977 11.5037 24.0977 12.4963 23.4069 12.8762L6.48192 22.1849C5.81546 22.5515 5 22.0693 5 21.3087V2.69127Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="titleCardList--metadataWrapper">
        <div className="titleCardList-title">
          <span className="titleCard-title_text">{episode.title}</span>
          <span>
            <span className="duration ellipsized">{episode.duration}</span>
          </span>
        </div>

        <p className="titleCard-synopsis previewModal--small-text">
          {episode.description}
        </p>
      </div>
    </div>
  );
};

export default EpisodeItem;
