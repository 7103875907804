import React from "react";
// import './ProfileListItem.css'; // Assuming CSS Modules or similar styling approach

const ProfileListItem = ({ profile, onClick }) => (
  <li className="profile">
    <div>
      <a className="profile-link" href="/#" onClick={onClick} id={profile.id}>
        <div className="avatar-wrapper">
          <div
            className="profile-icon"
            style={{ backgroundImage: `url(${profile.image})` }}
          ></div>
        </div>
        <span className="profile-name">{profile.name}</span>
      </a>
    </div>
  </li>
);

export default ProfileListItem;
