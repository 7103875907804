import React from "react";
import userProfilePicture from "../../assets/image/profile_2.png";

const UserProfile = () => {
  return (
    <div className="secondary-navigation">
      <div className="nav-element">
        <div className="account-menu-item">
          <div className="account-dropdown-button">
            <a href="/#" aria-label="Louis et Maman - Compte et paramètres">
              <span className="profile-link" role="presentation">
                <img className="profile-icon" src={userProfilePicture} alt="" />
              </span>
            </a>
            <span className="caret" role="presentation"></span>
          </div>

          <div
            className="account-drop-down sub-menu theme-lakira"
            style={{
              opacity: "1",
              transitionDuration: "150ms",
              display: "none",
            }}
          >
            <ul className="sub-menu-list sign-out-links">
              <li className="sub-menu-item">
                <a className="sub-menu-link" href="/#">
                  Se déconnecter
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
