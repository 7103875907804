import React from "react";
import { SiteLogo } from ".";

const SiteNavigation = () => {
  return (
    <>
      <SiteLogo />
      <ul className="tabbed-primary-navigation">
        <li className="navigation-tab">
          <a href="/#" className="current active">
            Accueil
          </a>
        </li>
      </ul>
    </>
  );
};

export default SiteNavigation;
