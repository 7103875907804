import React from "react";
import { SiteNavigation } from ".";
import { UserProfile } from ".";

const PageHeader = () => {
  return (
    <div
      className="pinning-header"
      style={{
        position: "sticky",
        top: "0",
        height: "auto",
        minHeight: "70px",
        zIndex: "1",
      }}
    >
      <div
        className="pinning-header-container"
        style={{ background: "transparent" }}
      >
        <div
          className="main-header has-billboard menu-navigation"
          role="navigation"
        >
          <SiteNavigation />
          <UserProfile />
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
